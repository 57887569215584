import React, { Component } from 'react';

class TextScramble extends Component {
  constructor(props) {
    super(props);
    this.state = { scrambledText: '', originalText: this.props.children };
    this.chars = '!<>-_\\/[]{}—=+*^?#________';
    this.update = this.update.bind(this);
    this.textRef = React.createRef();
  }

  componentDidMount() {
    this.setupIntersectionObserver();
  }

  setupIntersectionObserver() {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.setText(this.state.originalText);
          observer.unobserve(entry.target); // Stop observing after the first trigger
        }
      });
    }, { threshold: 0.5 }); // Threshold can be adjusted

    observer.observe(this.textRef.current);
  }

  setText(newText) {
    const oldText = this.state.scrambledText;
    const length = Math.max(oldText.length, newText.length);
    const promise = new Promise(resolve => this.resolve = resolve);
    this.queue = [];
    for (let i = 0; i < length; i++) {
      const from = oldText[i] || '';
      const to = newText[i] || '';
      const start = Math.floor(Math.random() * 60);
      const end = start + Math.floor(Math.random() * 60);
      this.queue.push({ from, to, start, end });
    }
    cancelAnimationFrame(this.frameRequest);
    this.frame = 0;
    this.update();
    return promise;
  }

  update() {
    let output = '';
    let complete = 0;
    for (let i = 0, n = this.queue.length; i < n; i++) {
      let { from, to, start, end, char } = this.queue[i];
      if (this.frame >= end) {
        complete++;
        output += to;
      } else if (this.frame >= start) {
        if (!char || Math.random() < 0.28) {
          char = this.randomChar();
          this.queue[i].char = char;
        }
        output += char;
      } else {
        output += from;
      }
    }
    this.setState({ scrambledText: output });

    if (complete === this.queue.length) {
      this.resolve();
      // Restart the animation with a delay
      // setTimeout(() => {
      //   this.setText(this.state.originalText);
      // }, 2000); // Delay of 2 seconds before restarting
    } else {
      this.frameRequest = requestAnimationFrame(this.update);
      this.frame += 0.5;
    }
  }

  randomChar() {
    return this.chars[Math.floor(Math.random() * this.chars.length)];
  }

  render() {
    return (
      <span ref={this.textRef}>{this.state.scrambledText}</span>
    );
  }
}

export default TextScramble;
