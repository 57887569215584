import React, { useEffect } from 'react';

const ImagePreloader = () => {
  useEffect(() => {
    const preloadImages = () => {
      const imageElements = document.getElementsByClassName('preload-image');
      for (let i = 0; i < imageElements.length; i++) {
        const image = new Image();
        image.src = imageElements[i].style.backgroundImage.slice(4, -1).replace(/['"]/g, '');
        console.log(image.src)
      }
    };

    preloadImages();
  }, []);

  return null;
};

export default ImagePreloader;
