import siemps from "./img/SEIMPS.png";
import twitter from "./img/twitter.png";
import discord from "./img/discord.png";
import twitter2 from "./img/twitter2.png";
import discord2 from "./img/discord2.png";
import React, { useState, useEffect } from 'react';
import ImagePreloader from "./preloader";
import clickhere from "./img/clickhere.gif";
import TextScramble from "./TextScramble";
import scrollbitch from "./img/scrollbitch.png"

import seimp1 from "./img/seimps/SEIMPS7.png";
import seimp2 from "./img/seimps/SEIMPS218.png";
import seimp3 from "./img/seimps/SEIMPS253.png";
import seimp4 from "./img/seimps/SEIMPS261.png";
import seimp5 from "./img/seimps/SEIMPS289.png";
import seimp6 from "./img/seimps/SEIMPS320.png";
import seimp7 from "./img/seimps/SEIMPS375.png";
import seimp8 from "./img/seimps/SEIMPS439.png";
import seimp9 from "./img/seimps/SEIMPS443.png";
import seimp10 from "./img/seimps/SEIMPS497.png";
import seimp11 from "./img/seimps/SEIMPS530.png";
import seimp12 from "./img/seimps/SEIMPS535.png";
import seimp13 from "./img/seimps/SEIMPS569.png";
import seimp14 from "./img/seimps/SEIMPS619.png";
import seimp15 from "./img/seimps/SEIMPS795.png";
import seimp16 from "./img/seimps/SEIMPS853.png";
import seimp17 from "./img/seimps/SEIMPS906.png";
import seimp18 from "./img/seimps/SEIMPS940.png";
import seimp19 from "./img/seimps/SEIMPS1060.png";
import seimp20 from "./img/seimps/SEIMPS1094.png";
import seimp21 from "./img/seimps/SEIMPS1188.png";
import seimp22 from "./img/seimps/SEIMPS1217.png";
import seimp23 from "./img/seimps/SEIMPS1225.png";
import seimp24 from "./img/seimps/SEIMPS1285.png";
import seimp25 from "./img/seimps/SEIMPS1287.png";


function App() {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 3000);
	}, []);


	return (
		<div className="app">
			<div className="preload-image"></div>
			<ImagePreloader />
			{loading ? <Loader /> : <MainContent />}
		</div>
	);
}

export default App;
function MainContent() {

	return (
		<div className="app">
			<div className="container main">
				<div className="toolbar">
					<img className="logo" src={siemps} alt="SEIMPS" />

					<div className="social">
						<a href="https://twitter.com/Seimpsnft" target="_blank">
							<img className="social-icon" src={twitter} alt="Twitter" />
						</a>
						<a href="https://discord.gg/KeaaSQrBQc">
							<img className="social-icon" src={discord} alt="Discord" />
						</a>
					</div>
				</div>
				<div class="scroll">
					<img src={scrollbitch} />
					<div class="chevron"></div>
					<div class="chevron"></div>
					<div class="chevron"></div>
				</div>
			</div>
			<div className="middiv">
				<TextScramble>BORN FROM THE DARK, IN THE BLACK CLOAK OF THE NIGHT...</TextScramble>
				<TextScramble>DEVILS MINIONS COME TO CLAIM THAT WHICH IS THEIR RIGHT...</TextScramble>
			</div>
			<div className="container seimps">
				<div className="showcase">
					<div className="gla">
						<Gallery />
					</div>

					<div className="content">
						<img className="title" src={siemps} alt="SEIMPS" />
						<div className="desc">
							<TextScramble>Welcome to the cryptic twisted world of SeImps... a universe where hell is overrun with lost souls and impish creatures that exist solely to do the devils bidding. Claim victims souls by securing whitelist today, and sacrificing your sei on mint day.</TextScramble> <br /><br />
							<TextScramble>Seimps is an exclusive collection of 6,666 nfts specially crafted for the SEI ecosystem, and serve as companions to aid you on your quest to dominate the blockchain. However, Seimps are more than just an nft and companion, they serve as entry into a gated Seimps community, and allow you to connect with your fellow Seimps holders, claim rewards, and participate in community events and giveaways. Collaborations with other sei projects are guaranteed, so secure your whitelist now.</TextScramble>
						</div>
						<a target="_blank" href="https://twitter.com/intent/tweet?text=Something%20malicious%20is%20brewing...%20%40Seimpsnft%20%0A%0ADon%27t%20%24SEI%20we%20didn%27t%20warn%20you...%20%E2%9B%93%F0%9F%A9%B8%F0%9F%92%80%E2%9A%B0%E2%98%A0%F0%9F%94%AA%E2%9B%93" className="sign">
							<img src={clickhere} alt="Click Here" />
						</a>
					</div>
				</div>
			</div>
			<div className="footer">

				<a href="https://twitter.com/Seimpsnft" target="_blank">
					<img src={twitter2} alt="Twitter" />
				</a>
				<a href="#" target="_blank">
					<img className="sim" src={"./logo512.png"} alt="SEIMPS" />
				</a>
				<a href="https://discord.gg/KeaaSQrBQc" target="_blank">
					<img src={discord2} alt="Discord" />
				</a>
				<div className="copy">&copy; 2024, SIEMPS.COM INC.</div>
			</div>
		</div>
	)
}

const imageSources = [
	seimp1,
	seimp2,
	seimp3,
	seimp4,
	seimp5,
	seimp6,
	seimp7,
	seimp8,
	seimp9,
	seimp10,
	seimp11,
	seimp12,
	seimp13,
	seimp14,
	seimp15,
	seimp16,
	seimp17,
	seimp18,
	seimp19,
	seimp20,
	seimp21,
	seimp22,
	seimp23,
	seimp24,
	seimp25,
];


const Gallery = () => {
	// Initialize a state variable with the first four images
	const [currentImages, setCurrentImages] = useState(imageSources.slice(0, 4));

	useEffect(() => {
		// Set up the interval to update images every 2 seconds
		const intervalId = setInterval(() => {
			setCurrentImages(prevImages => {
				// Determine the next index
				const nextIndex = imageSources.indexOf(prevImages[0]) + 1;
				// Slice the next four images, or wrap around to the beginning of the array
				return imageSources.slice(nextIndex, nextIndex + 4).concat(
					imageSources.slice(0, Math.max(0, nextIndex + 4 - imageSources.length))
				);
			});
		}, 2000);

		// Clear the interval on component unmount
		return () => clearInterval(intervalId);
	}, []);

	return (
		<div className="gallery">
			{currentImages.map((src, index) => (
				<img key={index} src={src} alt={`Image ${index + 1}`} />
			))}
		</div>
	);
};

function Loader() {
	const [selectedLoader, setSelectedLoader] = useState(null);

	useEffect(() => {
		const loaders = [
			<div key="loader1" className="loader1"></div>,
			<div key="loader2" className="loader2"></div>,
			<div key="loader3" className="loader3"></div>
		];

		const randomLoader = loaders[Math.floor(Math.random() * loaders.length)];
		setSelectedLoader(randomLoader);
	}, []);
	return (
		<div className="loader-container">
			{selectedLoader}
		</div>
	)
}